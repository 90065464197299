import React from "react"
import Layout from "../../components/h/layout"

const HalloweenCPage = () => (
  <Layout>
    <h2>This is the home of your ghost he sits up high.</h2>
    <h2>
      Static sometimes scratches this place, we tell him stop it fluffy guy.
    </h2>
  </Layout>
)

export default HalloweenCPage
